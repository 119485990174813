<template>
    <div>
        <table-violators/>
    </div>
</template>

<script>
import TableViolators from '@/components/violators/table.vue'
export default {
  components: { TableViolators },
    data () {
        return {
           
        }
    }
    
}
</script>